<script lang="ts" setup>
  import { storeToRefs } from 'pinia'
  import { useGameContentStore } from '~/store/GameContent'
  import useMarkdown from '~/composables/Markdown/Markdown'
  import ListLocation from '~/components/ListLocation/ListLocation.vue'
  import { OverlayComponent, OverlayId } from '~/model/Overlay/Overlay'
  import { UiOverlayFacet } from '~/components/Ui/UiOverlay/UiOverlayFacet'
  import { useOverlayStore } from '~/store/Overlay'
  import { GameDistrictId } from '~/model/GameDistrict/GameDistrict'
  import useGameDistrict from '~/composables/GameDistrict/GameDistrict'
  import { onMounted, watch } from '#imports'
  import useTranslation from '~/composables/Translation/Translation'

  const gameContentStore = useGameContentStore()

  const { districtContent } = storeToRefs(gameContentStore)
  const { openOverlay } = useOverlayStore()
  const { t } = useTranslation()
  const { parseMarkdown } = useMarkdown()
  const { isVisited } = useGameDistrict()

  const requestOverlay = () => {
    openOverlay(OverlayId.FullScreen, {
      transition: 'trs-simple-fade',
      facets: [UiOverlayFacet.FullScreen],
      closeWithKey: 'Escape',
      component: OverlayComponent.OverlayContentLearnSnack,
      disableScroll: true,
      props: {},
    })
  }

  onMounted(() => {
    watch(
      districtContent,
      () => {
        if (!districtContent.value) {
          return
        }

        if (!isVisited(districtContent.value.id as GameDistrictId)) {
          requestOverlay()
        }
      },
      { immediate: true },
    )
  })
</script>

<template>
  <div class="c-sidebar-content-district s-sidebar-content u-typography-root">
    <ClientOnly>
      <template v-if="districtContent">
        <h1 class="s-sidebar-content__title">
          {{ districtContent.name }}
        </h1>
        <div
          class="s-sidebar-content__description"
          v-html="parseMarkdown(districtContent.introduction)"
        />
      </template>
    </ClientOnly>

    <div class="c-sidebar-content-district__actions">
      <button class="btn btn--medium btn--primary" @click="requestOverlay">
        {{ t('sidebar-content-district.action-learn-snack.label') }}
      </button>
    </div>

    <div class="s-sidebar-content__entries">
      <h2 class="s-sidebar-content__entries-title">
        {{ t('sidebar-content-district.locations') }}
      </h2>
      <p class="u-reset s-sidebar-content__instructions">
        {{ t('sidebar-content-district.instructions') }}
      </p>

      <ClientOnly>
        <ListLocation
          v-if="districtContent"
          :district-id="districtContent.id"
          :show-nested="true"
          class="s-sidebar-content__entries-content"
        />
      </ClientOnly>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .c-sidebar-content-district__actions {
    display: flex;
    justify-content: center;
    margin-top: 32px;
  }
</style>
